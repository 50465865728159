<template>
  <div class="faq">
    <section class="faq-content">
      <span class="faq-heading">FAQ</span>
      <span class="faq-subheading"
        >Explore our FAQ section for quick answers to common questions about our
        services, policies, and more. If you can't find what you're looking for,
        feel free to reach out to our team. We're here to help!</span
      >
      <div
        v-for="(pair, index) in FAQ"
        :key="index"
        :pair="index"
        class="faq-pair"
      >
        <span
          class="pair-q"
          @click="toggleAnswer(index)"
          :style="index === 0 ? 'border-top: none;' : ''"
        >
          <span>{{ pair.q }}</span>
          <svg
            v-if="!visibleAnswers.includes(index)"
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-chevron-down"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
            />
          </svg>
          <svg
            v-else
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-chevron-up"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"
            />
          </svg>
        </span>
        <span class="pair-a" v-html="pair.a"> </span>
      </div>
    </section>
  </div>
</template>
<script>
import { ref } from "vue";
export default {
  setup() {
    const visibleAnswers = ref([]);
    const FAQ = ref([
      {
        q: "How long does the average project take?",
        a: `Please allow 7-14 days for the delivery process for all projects. For video services, the 7-14 day delivery process starts after the final date of filming. Extensive projects may need more time, which we can discuss during the consultation.<br /><br />A rush fee will be applied if you need your project within a timeframe less than 7 days. For more information about the delivery process, timelines, rush fees, and other information, please view the <a href='/privacy-procedures' style="color: #2c3e50">Policies and Procedures</a> PDF.`,
      },
      {
        q: "What is the process for your services?",
        a: "1. Consultation<br />2. Project Proposal<br />3. Signed Agreement<br />4. Content Creation<br />5. Review<br />6. Final Deliverables",
      },
      {
        q: "Is a deposit required?",
        a: "Yes a 50% deposit is required before work can begin on the client project. Work will not start until the deposit is paid.",
      },
      {
        q: "Can I cancel my project?",
        a: "We hate to see you go, but yes, you can cancel the project at any time; however,  please be advised you will not get a refund for your deposit once work has commenced.",
      },
      {
        q: "Can I get a refund on my deposit?",
        a: "No. Once the deposit is paid, we will begin working on your project which takes into account time, labor,  and resources. Under no circumstances will the deposit be refunded once work has commenced.",
      },
      {
        q: "What are the accepted forms of payment?",
        a: "We accept credit and debit card payments via Square. You can also pay with a written check.<br /><br />C Squared Media does not accept payments via Zelle, Cash App, Apple Pay, Venmo, ACH payments, or cryptocurrencies.",
      },
      {
        q: "Can I pick the music for my video?",
        a: "Yes, absolutely! Please note, that we use royalty free music so you can share your videos online without the risk of it being removed. If you choose to use a copyrighted song, you will need to obtain written permission from the person, people, or company who owns the rights to the song. ",
      },
      {
        q: "Can I participate in the editing process for my media items?",
        a: "Yes, we want to hear from you to ensure you are satisfied. We will provide progress updates and send drafts of the media items for your review. You are guaranteed two rounds of complimentary revisions for each item e.g. video, social media content calendar, email newsletter, blog post, etc.<br /><br />Additional revisions can be made, but will cost $20 for each round of revisions.",
      },
      {
        q: "Can I have access to the raw footage of my videos?",
        a: "Yes, we will store raw footage for up to 30 days upon completion of the project. Should you need access to the raw footage for a longer time period, we will charge a storage fee at $10 a month for up to 12 months.",
      },
      {
        q: "How can we contact you?",
        a: "An invitation for Zoom or Google Meet will be sent for the initial consultation. All other communications can be completed by either phone call, Zoom, Google Meet, or email.",
      },
      {
        q: "How soon will we hear from you?",
        a: "Please allow up to 72 hours for a response time following the submission of the consultation request or contact form.",
      },
    ]);

    function toggleAnswer(index) {
      const element = document.querySelector(`[pair="${index}"] .pair-a`);
      if (element.style.display === "none" || element.style.display === "") {
        element.style.display = "inline";
        visibleAnswers.value.push(index);
      } else {
        element.style.display = "none";
        visibleAnswers.value = visibleAnswers.value.filter(
          (value) => value !== index
        );
      }
    }

    return { FAQ, toggleAnswer, visibleAnswers };
  },
};
</script>


<style scoped>
.faq {
  padding-top: 4rem;
  background: #a9a9a9;
}

.faq-content {
  display: flex;
  flex-flow: row wrap;
  width: 80%;
  max-width: 50rem;
  margin: 0 auto;
  padding: 20px;
}

.faq-heading {
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  font-size: 28px;
  color: rgba(0, 31, 63, 1);
  width: 100%;
  text-align: left;
  margin-bottom: 10px;
}

.faq-subheading {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  text-align: left;
  width: 85%;
  margin-bottom: 10px;
}

.faq-pair {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
}

.pair-q {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 16px;
  width: 100%;
  text-align: left;
  border-top: 1px solid rgb(0, 51, 102);
  height: 4rem;
  color: #001f3f;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 2rem;
  margin: 5px auto;
  cursor: pointer;
}

.pair-q svg {
  margin-right: 1rem;
}

.pair-a {
  font-family: "Raleway", sans-serif;
  font-size: 16px;
  width: 100%;
  text-align: left;
  padding: 2rem;
  font-weight: bold;
  display: none;
  padding-top: 0.5rem;
}

a {
  color: black !important;
}

@media only screen and (max-width: 1010px) {
  .faq-content {
    width: 100%;
  }

  .pair-q {
    padding-left: 0.1rem;
  }

  .pair-a {
    padding-left: 0.2rem;
    padding-right: 0.2rem;
  }
}
</style>