<template>
  <section class="services">
    <section class="service videography">
      <span class="service-name">VIDEOGRAPHY</span>
      <div class="service-description">
        Transform your most cherished moments into cinematic masterpieces with our professional videography service. We
        are dedicated to capturing the essence of your events, stories, and visions. We go beyond simply recording footage
        &#45; we craft compelling visual narratives that engage, inspire, and leave a lasting impact.
      </div>
    </section>
    <section class="service editing">
      <div class="service-description">
        Take your video content to the next level with our video editing services.<br />
        Whether you have existing videos that need refinement or fresh content that requires creative editing, we bring
        your vision to life. From storytelling to color grading, our editing process is a meticulous craft that ensures
        your videos not only look amazing but also convey your message effectively.
      </div>
      <span class="service-name">EDITING</span>
    </section>
    <section class="service content-marketing">
      <span class="service-name">CONTENT<br />MARKETING</span>
      <div class="service-description">
        In today's digital landscape, content is king, and we're here to help you rule. We specialize in creating and
        distributing valuable, relevant, and consistent content that resonates with your target audience.<br /><br />
        Our content marketing strategies aim to drive engagement, build brand loyalty, and fuel growth. Whether it's blog
        posts, social media content, videos, or infographics, we craft content that tells your story, showcases your
        expertise, and positions your brand as a trusted industry leader.
      </div>
    </section>
    <section class="service digital-marketing">
      <div class="service-description">
        Maximize your online impact with our comprehensive digital marketing services. In the digital realm, your brand's
        success hinges on a strategic and data-driven approach. That's where we come in. From social media management,
        email marketing, and more, we create tailored strategies that maximize your reach, conversions, and return on
        investment (ROI). We believe in using the data to tell a story, and help you not only bring more visitors to your
        site, but visitors who will become valuable, loyal customers.
      </div>
      <span class="service-name">DIGITAL<br />MARKETING</span>
    </section>
    <section class="service media-analytics">
      <span class="service-name">ANALYTICS</span>
      <div class="service-description">
        In today's data-driven world, it's essential to track, measure, and analyze the performance of your media and
        website efforts. We break down the charts, graphs, and numbers to provide you with valuable insights and
        actionable data that empower you to optimize your strategies, improve targeting, and make informed decisions. We
        go beyond the numbers on your website, Tik Tok, Instagram, Facebook, X page, and more to help you understand the
        story behind the metrics to unlock your campaign&#39;s full potential.

      </div>
    </section>
  </section>
</template>

<script>
export default {};
</script>

<style scoped>
.services {
  min-height: 100vh;
  position: relative;
  padding-top: 3.5rem;
}

.service {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  position: sticky;
  bottom: 0;
  z-index: -1;
  background: #003366;
}

.service:nth-of-type(2) {
  z-index: -2;
}

.service:nth-of-type(3) {
  z-index: -3;
}

.service:nth-of-type(4) {
  z-index: -4;
}

.service:nth-of-type(5) {
  z-index: -5;
}

.service:nth-of-type(6) {
  z-index: -6;
}

.service-name {
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  font-size: 4rem;
  color: #ff5733;
  line-height: 1;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #001f3f;
  padding: 0 1rem;
  width: 50%;
}

.service-description {
  flex: 1;
  height: 100%;
  color: #ff884d;
  background: #003366;
  display: flex;
  align-items: center;
  padding: 20px 30px;
  text-align: left;
  font-family: "Raleway", sans-serif;
  font-weight: bold;
}

.service-description:nth-of-type(2n) {
  text-align: right;
}

@media only screen and (max-width: 1010px) {
  .service {
    align-content: flex-start;
  }

  .service-name,
  .service-description {
    width: 100%;
  }

  .service-name {
    font-size: 2.5rem;
    height: 35%;
  }

  .service-description {
    flex: 1;
    font-size: 14px;
    height: 65%;
  }

  .service.editing,
  .service.digital-marketing,
  .service.website-analytics {
    flex-direction: column-reverse;
  }
}</style>