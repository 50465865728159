<template>
  <nav class="navbar navbar-expand-lg fixed-top navbar-dark">
    <router-link to="/" class="navbar-brand">
      <img src="@/assets/C2-logo.png" alt="CSquared Media, LLC logo" />
    </router-link>
    <button
      class="navbar-toggler"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#navbarNav"
      aria-controls="navbarNav"
      aria-expanded="false"
      aria-label="Toggle navigation"
      id="navbarToggler"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse justify-content-end" id="navbarNav">
      <div class="navbar-nav">
        <router-link
          @click="collapseNav"
          to="/"
          exact
          active-class="active"
          class="nav-item nav-link"
          >Home</router-link
        >
        <router-link
          @click="collapseNav"
          to="/founder"
          exact
          active-class="active"
          class="nav-item nav-link"
          >Meet The Founder</router-link
        >
        <router-link
          @click="collapseNav"
          to="/services"
          exact
          active-class="active"
          class="nav-item nav-link"
          >Services</router-link
        >
        <router-link
          @click="collapseNav"
          to="/faq"
          exact
          active-class="active"
          class="nav-item nav-link"
          >FAQ</router-link
        >
        <router-link
          @click="collapseNav"
          to="/contact"
          exact
          active-class="active"
          class="nav-item nav-link"
          >Contact Us</router-link
        >
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  setup() {
    function collapseNav() {
      let isExpanded = document.querySelector(".navbar-collapse.show");

      if (isExpanded) {
        const navbarCollapse = document.getElementById("navbarToggler");
        navbarCollapse.click();
      }
    }

    window.addEventListener("scroll", function () {
      let isExpanded = document.querySelector(".navbar-collapse.show");

      if (isExpanded) {
        const navbarCollapse = document.getElementById("navbarToggler");
        navbarCollapse.click();
      }
    });

    return { collapseNav };
  },
};
</script>

<style scoped>
.navbar {
  background: rgba(0, 31, 63, 1);
  padding-left: 1rem;
  padding-right: 1rem;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  z-index: 100000;
  max-width: 2000px;
  margin: 0 auto;
}

.navbar-brand img {
  max-height: 100%;
  max-width: 7.5rem;
}

router-link a {
  text-decoration: none;
}

.navbar-toggler {
  border: none;
}

.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
    outline: none;
    box-shadow: none;
}
</style>