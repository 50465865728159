<template>
  <footer>
    <CookiesBanner :showCookiesBanner="showCookiesBanner" @dismissCookies="dismissCookies" />

    <span class="footer-policies">
      <router-link to="/privacy">Privacy Policy</router-link>
      <span>|</span>
      <span @click="toggleCookiesBanner" class="span-policy">Cookies</span>
    </span>
    <img class="footer-logo" src="@/assets/C2-logo.png" alt="logo" />
    <span class="footer-owner-text"> Courtney Cole, Owner. </span>
    <div class="contact-info">
      <a href="tel:+15409031732" style="margin-right: 5px">
        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="lightgray" class="bi bi-telephone-fill"
          viewBox="0 0 16 16">
          <path fill-rule="evenodd"
            d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
        </svg>
        <span class="small-text contact-text">+1 (540)-903-1732</span>
      </a>
    </div>
    <a href="mailto:courtney@c-squared-media.com" class="w-100 footer-contact">
      <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="lightgray" class="bi bi-envelope-fill"
        viewBox="0 0 16 16">
        <path
          d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555zM0 4.697v7.104l5.803-3.558L0 4.697zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757zm3.436-.586L16 11.801V4.697l-5.803 3.546z" />
      </svg>
      <span class="small-text contact-text">courtney@c-squared-media.com</span>
    </a>
    <a href="https://www.facebook.com/profile.php?id=61552730758294" target="_blank" rel="noopener noreferrer"
      class="w-100 footer-contact">
      <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="lightgray" class="bi bi-facebook"
        viewBox="0 0 16 16">
        <path
          d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
      </svg>
      <span class="small-text contact-text">Stay Connected on Facebook</span>
    </a>
    <a href="https://www.instagram.com/csquaredmediallc/" target="_blank" rel="noopener noreferrer"
      class="w-100 footer-contact">
      <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="lightgray" class="bi bi-instagram"
        viewBox="0 0 16 16">
        <path
          d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z" />
      </svg>
      <span class="small-text contact-text">Discover More on Instagram</span>
    </a>
    <span class="small-text">
      Copyright &copy; {{ getCurrentYear() }} C Squared Media, LLC. All
      rights reserved.
    </span>
    <span class="small-text">Powered by The NixTech Company, LLC</span>
  </footer>
</template>

<script>
import { ref, onMounted } from "vue"
import CookiesBanner from "./CookiesBanner"

export default {
  components: {
    CookiesBanner
  },
  setup() {
    const showCookiesBanner = ref(false);

    function getCurrentYear() {
      return new Date().getFullYear();
    }

    function getCookiePreferences() {
      const name = 'csmCookieConsent=';
      const decodedCookie = decodeURIComponent(document.cookie);
      const cookieArray = decodedCookie.split(';');

      for (let i = 0; i < cookieArray.length; i++) {
        let cookie = cookieArray[i].trim();

        if (cookie.indexOf(name) === 0) {
          const cookieValue = cookie.substring(name.length);

          const jsonString = decodeURIComponent(cookieValue);

          const expiresIndex = cookie.indexOf('expires=');
          if (expiresIndex !== -1) {
            const expiresSubstring = cookie.substring(expiresIndex + 'expires='.length);
            const expiresDate = new Date(expiresSubstring);

            // Check if the expiration date is in the past
            if (expiresDate < new Date()) {
              return {
                allowAll: false,
                strictlyNecessary: false,
                preferenceSet: false,
                preferences: {
                  functional: true,
                  technical: true,
                  marketing: true
                }
              }
            }
          }

          return JSON.parse(jsonString);
        }
      }

      return {
        allowAll: false,
        strictlyNecessary: false,
        preferenceSet: false,
        preferences: {
          functional: true,
          technical: true,
          marketing: true
        }
      }
    }

    function areCookiesAccepted() {
      var cookiePreferences = getCookiePreferences()
      return cookiePreferences.allowAll === true || cookiePreferences.strictlyNecessary === true || cookiePreferences.preferenceSet === true;
    }

    onMounted(() => {
      showCookiesBanner.value = !areCookiesAccepted();
    })

    function toggleCookiesBanner() {
      showCookiesBanner.value = true
    }

    function dismissCookies() {
      showCookiesBanner.value = false;
    }

    return {
      getCurrentYear, showCookiesBanner, toggleCookiesBanner, dismissCookies
    };
  },
};
</script>

<style scoped>
footer {
  height: auto;
  background: rgba(0, 31, 63, 1);
  color: white;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  padding-left: 30px;
  text-align: left;
  font-family: "Raleway", sans-serif;
  font-weight: bold;
  letter-spacing: 1.5px;
}

footer a {
  color: white;
}

.footer-policies {
  text-align: center;
  width: 100%;
  font-size: 11px;
  margin-bottom: 10px;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}

.footer-logo {
  max-height: 4.5rem;
  margin-bottom: 10px;
}

.footer-policies a,
.footer-policies .span-policy {
  cursor: pointer;
  margin: 0 10px;
}

.footer-contact {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 7px;
  cursor: pointer;
}

.contact-info {
  display: flex;
  justify-content: flex-start;
}

.small-text {
  font-size: 12px;
  margin-top: 3px;
  width: 100%;
}

.contact-text {
  margin-left: 9px;
  cursor: pointer;
}

.footer-owner-text {
  font-size: 14px;
  margin-bottom: 5px;
}

footer a {
  text-decoration: none;
}
</style>