<template>
  <section class="founder">
    <div class="founder-content">
      <span class="founder-heading">Meet The Woman<br class="heading-br" /> Behind C Squared Media
      </span>
      <div class="founder-image">
        <div class="image-container">
          <img src="@/assets/csquared-founder-2.jpg" alt="Courtney Cole, Founder" />
        </div>
      </div>
      <div class="founder-description">
        <span class="founder-bio intro">
          <span class="intro-text">Hello, I'm Courtney Cole, a passionate storyteller with a journey
            that spans from the world of media and journalism to the creation of
            C Squared Media, LLC.</span>
          <br />
          <br />
          <span class="intro-description">With a deep and unwavering passion for both the art of storytelling
            and the craft of capturing moments through the lens, I've uncovered
            the profound impact of sharing personal narratives. From my early
            experiences in freelance videography to my tenure in broadcast
            journalism, I've had the privilege of filming, writing, and
            connecting with various people across the country. <br />
            <br />
            Now, I'm delighted to extend my storytelling expertise to you
            through C Squared Media, LLC. Join me on this exciting adventure as
            we bring your unique narratives to life.
          </span>
        </span>

        <div class="founder-socials"></div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style scoped>
.founder {
  margin-top: 3.3rem;
  min-height: calc(100vh - 3.3rem);
  height: auto;
  display: flex;
  background: #a9a9a9;
  align-items: center;
}

.founder-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: row wrap;
  margin: 2.5rem auto;
}

.founder-heading {
  width: 100%;
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  color: rgba(0, 31, 63, 1);
  text-align: center;
  font-size: 24px;
  margin-bottom: 1.5rem;
}

.founder-image {
  width: 40%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-flow: row wrap;
  align-content: center;
  padding-right: 1.5rem;
}

.image-container {
  width: 22rem;
  display: flex;
  height: 25rem;
  justify-content: flex-end;
}

.image-container img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 5px;
}

.founder-name {
  width: 100%;
}

.founder-description {
  width: 60%;
  height: 100%;
  display: flex;
  flex-flow: row wrap;
  padding: 30px;
  align-content: center;
  max-width: 60rem;
  padding-top: 0px;
}

.founder-bio {
  font-size: 14px;
  font-family: "Raleway", sans-serif;
  font-weight: bold;
  color: black;
  margin-top: 20px;
  text-align: left;
}

.founder-bio.intro {
  width: 70%;
}

.intro-text {
  font-size: 24px;
}

.intro-description {
  width: 90%;
}

.heading-br {
  display: none;
}

@media only screen and (max-width: 1010px) {

  .founder-image,
  .founder-description {
    width: 100%;
  }

  .founder-heading {
    padding: 0 1rem;
  }

  .heading-br {
    display: inline;
  }

  .founder-description {
    padding-top: 0;
  }

  .founder-image {
    justify-content: center;
  }

  .image-container {
    width: 18rem;
    height: 21rem;
    justify-content: center;
  }

  .founder-bio.intro {
    width: 95%;
    margin: 20px auto;
  }

  .intro-text {
    font-size: 22px;
  }
}
</style>