import { ref, getDownloadURL } from "firebase/storage";

import { collection, getDoc, doc, addDoc } from "firebase/firestore";

import { db, storage } from "../main";

export async function getMainVideoMontage() {
  let main = {};

  try {
    const docRef = doc(db, "admin", "videos");
    const docSnap = await getDoc(docRef);
    const data = docSnap.data();

    const urlString = data.main;

    const gsReferenceUrl = ref(storage, `content/videos/${urlString.url}`);
    const gsReferencePoster = ref(
      storage,
      `content/posters/${urlString.poster}`
    );

    main.url = await getDownloadURL(gsReferenceUrl);
    main.poster = await getDownloadURL(gsReferencePoster);
  } catch (_e) {}

  return main;
}

export async function getVisionVideos() {
  let urls = [];

  try {
    const docRef = doc(db, "admin", "videos");
    const docSnap = await getDoc(docRef);
    const data = docSnap.data();

    const visionVideos = data.visions;

    for (const vision of visionVideos) {
      const gsReferenceUrl = ref(storage, `content/videos/${vision.url}`);
      const gsReferencePoster = ref(
        storage,
        `content/posters/${vision.poster}`
      );

      const url = await getDownloadURL(gsReferenceUrl);
      const poster = await getDownloadURL(gsReferencePoster);
      const id = vision.id;

      urls.push({ url: url, id: id, poster: poster });
    }
  } catch (_e) {}

  return urls;
}

export async function getPrivacyPolicy() {
  try {
    const gsReferenceUrl = ref(storage, `C Squared Media, LLC/Privacy Policy.pdf`);
    const url = await getDownloadURL(gsReferenceUrl);

    return url
  } catch (_e) {
    return null;
  }
}

export async function getPrivacyAndProceduresPolicy() {
  try {
    const gsReferenceUrl = ref(storage, `C Squared Media, LLC/Policies and Procedures.pdf`);
    const url = await getDownloadURL(gsReferenceUrl);

    return url
  } catch (_e) {
    return null;
  }
}

export async function uploadCollaboration(collaboration) {
  try {
    const collaborationsRef = collection(db, "collaborations");

    collaboration.date = new Date();
    collaboration.read = false;
    collaboration.archived = false;

    await addDoc(collaborationsRef, collaboration);
  } catch (_e) {}
}

export async function uploadInquiry(inquiry) {
  try {
    const inquiriesRef = collection(db, "inquiries");

    inquiry.date = new Date();
    inquiry.read = false;
    inquiry.archived = false;

    await addDoc(inquiriesRef, inquiry);
  } catch (_e) {}
}
