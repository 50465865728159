<template>
  <div v-if="showCookiesBanner" class="cookies-banner">
    <div class="cookies-banner-inner">
      <div v-if="showCookiesInformation" class="cookies-info">
        <header class="cookies-info-header">
          <span>Cookies Preferences</span>
          <svg @click="toggleCookiesInformation" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
            fill="currentColor" class="bi bi-x-circle-fill dismiss-cookies" viewBox="0 0 16 16">
            <path
              d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
          </svg>
        </header>
        <span class="cookies-info-header-description">Cookies are small text files that are stored on your device when
          you visit a website. They serve various
          functions, such as enhancing your browsing experience, remembering your preferences, and helping the website
          analyze its traffic. Your continued use of our website implies your consent to the described cookie practices
          outlined in this policy. If you have any questions or concerns regarding our cookie practices, feel free to
          reach
          out to us.
        </span>
        <div class="info">
          <div class="info-text">
            <span class="info-header">
              Strictly Necessary Cookies
            </span>
            <span class="info-description">
              Strictly necessary cookies are essential for the basic functionality of a website. They enable core features
              such as page navigation and access to secure areas. The website cannot function properly without these
              cookies.
            </span>
          </div>
          <div class="cookie-status">
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" id="strictly-check" checked disabled>
              <label class="form-check-label" for="strictly-check"></label>
            </div>
          </div>
        </div>
        <div class="info">
          <div class="info-text">
            <span class="info-header">
              Functional Cookies
            </span>
            <span class="info-description">Functional cookies enhance the usability of a website by providing additional
              features and personalization. These cookies remember choices you make, such as language preferences, and
              tailor
              the website experience to better suit your needs.</span>
          </div>
          <div class="cookie-status">
            <div class="form-check form-switch">
              <input @change="setCookiePreferences(false)" v-model="userPreferences.preferences.functional"
                class="form-check-input" type="checkbox" id="functional-check">
              <label class="form-check-label" for="functional-check"></label>
            </div>
          </div>
        </div>
        <div class="info">
          <div class="info-text">
            <span class="info-header">Technical Cookies</span>
            <span class="info-description">
              Technical cookies encompass a broad category of cookies that are necessary for various technical aspects of
              a
              website. This includes cookies that manage data traffic, ensure security, and facilitate the performance of
              web
              pages.
            </span>
          </div>
          <div class="cookie-status">
            <div class="form-check form-switch">
              <input @change="setCookiePreferences(false)" v-model="userPreferences.preferences.technical"
                class="form-check-input" type="checkbox" id="technical-check">
              <label class="form-check-label" for="technical-check"></label>
            </div>
          </div>
        </div>
        <div class="info">
          <div class="info-text">
            <span class="info-header">
              Marketing Cookies
            </span>
            <span class="info-description">
              Marketing cookies are used to track and collect information about your browsing behavior. They are employed
              to
              deliver more personalized advertisements and promotions. These cookies play a role in creating a user
              profile
              based on your interests and showing you targeted content.
            </span>
          </div>
          <div class="cookie-status">
            <div class="form-check form-switch">
              <input @change="setCookiePreferences(false)" v-model="userPreferences.preferences.marketing"
                class="form-check-input" type="checkbox" id="marketing-check">
              <label class="form-check-label" for="marketing-check"></label>
            </div>
          </div>
        </div>
        <div class="info">
          <div class="info-text">
            <span class="info-header">
              Third-Party Cookies
            </span>
            <span class="info-description">
              Third-party services used within C Squared Media may set
              their own cookies to collect data and provide functionalities. It's important to note that these third-party
              cookies are subject to
              the privacy policies of the respective third-party services. You
              can adjust your preferences or obtain more information about third-party cookies through the privacy
              settings on
              the platforms providing these services.
            </span>
          </div>
        </div>
      </div>
      <div v-else class="use-of-cookies">
        <header class="cookies-header">
          <span>How We Use Cookies</span>
          <svg @click="dismissCookies" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
            class="bi bi-x-circle-fill dismiss-cookies" viewBox="0 0 16 16">
            <path
              d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
          </svg>
        </header>
        <span class="cookies-text">
          We use cookies to enhance your experience on our website. You have the flexibility to control and manage your
          <span @click="toggleCookiesInformation" class="cookies-link">Cookie Preferences</span>. Explore further details
          about our cookie usage in our
          <router-link class="cookies-link" to="/privacy">Privacy
            Policy</router-link>.
        </span>
        <div class="cookies-options">
          <button @click="acceptAllCookies" class="btn btn-sm">Accept all cookies</button>
          <button @click="acceptNecessaryCookies" class="btn btn-sm">Necessary cookies only</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, watch } from "vue"

export default {
  props: ['showCookiesBanner'],
  setup(props, { emit }) {
    const showCookiesBanner = ref(props.showCookiesBanner ?? false)
    const showCookiesInformation = ref(false)

    const userPreferences = ref({
      allowAll: false,
      strictlyNecessary: false,
      preferenceSet: false,
      preferences: {
        functional: true,
        technical: true,
        marketing: true
      }
    });

    watch(() => props.showCookiesBanner, (newValue) => {
      showCookiesBanner.value = newValue;
    });

    function acceptAllCookies() {
      userPreferences.value = {
        allowAll: true,
        preferenceSet: true,
        strictlyNecessary: false,
        preferences: {
          functional: true,
          technical: true,
          marketing: true
        }
      }

      setCookiePreferences(true)
      dismissCookies()
    }

    function acceptNecessaryCookies() {
      userPreferences.value = {
        allowAll: false,
        preferenceSet: false,
        strictlyNecessary: true,
        preferences: {
          functional: false,
          technical: false,
          marketing: false
        }
      }

      setCookiePreferences(true)
      dismissCookies()
    }

    onMounted(() => {
      userPreferences.value = getCookiePreferences()
    });

    function getCookiePreferences() {
      const name = 'csmCookieConsent=';
      const decodedCookie = decodeURIComponent(document.cookie);
      const cookieArray = decodedCookie.split(';');

      for (let i = 0; i < cookieArray.length; i++) {
        let cookie = cookieArray[i].trim();

        if (cookie.indexOf(name) === 0) {
          const cookieValue = cookie.substring(name.length);

          const jsonString = decodeURIComponent(cookieValue);

          const expiresIndex = cookie.indexOf('expires=');
          if (expiresIndex !== -1) {
            const expiresSubstring = cookie.substring(expiresIndex + 'expires='.length);
            const expiresDate = new Date(expiresSubstring);

            // Check if the expiration date is in the past
            if (expiresDate < new Date()) {
              return {
                allowAll: false,
                strictlyNecessary: false,
                preferenceSet: false,
                preferences: {
                  functional: true,
                  technical: true,
                  marketing: true
                }
              }
            }
          }

          return JSON.parse(jsonString);
        }
      }

      return {
        allowAll: false,
        strictlyNecessary: false,
        preferenceSet: false,
        preferences: {
          functional: true,
          technical: true,
          marketing: true
        }
      }
    }

    function toggleCookiesInformation() {
      showCookiesInformation.value = !showCookiesInformation.value;
    }

    function dismissCookies() {
      showCookiesBanner.value = false
      emit('dismissCookies')
    }

    function setCookiePreferences(manuallyAccepted = false) {
      if (manuallyAccepted) {
        dismissCookies()
      } else {
        userPreferences.value.allowAll = false;
        userPreferences.value.strictlyNecessary = false;
        userPreferences.value.preferenceSet = true
      }

      const expirationDate = new Date(Date.now() + 365 * 24 * 60 * 60 * 1000);
      const cookieValue = JSON.stringify(userPreferences.value);
      document.cookie = `csmCookieConsent=${encodeURIComponent(cookieValue)}; expires=${expirationDate.toUTCString()}; path=/`;
    }

    return { acceptAllCookies, acceptNecessaryCookies, showCookiesBanner, dismissCookies, showCookiesInformation, toggleCookiesInformation, setCookiePreferences, userPreferences }
  }
}
</script>

<style scoped>
.cookies-banner {
  width: 100%;
  max-height: 70vh;
  position: fixed;
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  z-index: 5;
}

.cookies-banner-inner {
  background: rgba(0, 31, 63);
  width: 100%;
  max-width: 40rem;
  min-height: 4rem;
  margin: 5px 15px;
  display: flex;
  flex-flow: row wrap;
  border: 1px solid #ff5733;
  border-radius: 5px;
  max-height: 100%;
  overflow-y: auto;
}

.cookies-info {
  background: rgba(0, 31, 63);
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  z-index: 6;
  color: #f4ebd0;
  position: relative;
}

.cookies-info-header {
  width: 100%;
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 10px;
  position: sticky;
  top: 0;
  background: rgba(0, 31, 63);
  height: 2.5rem;
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  z-index: 1;
}

.cookies-info-header-description {
  text-align: left;
  font-size: 12px;
  margin-bottom: 10px;
  padding: 0 1rem;
}

.cookies-info .info {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  margin: 5px auto;
  padding: 0 1rem;
}

.cookies-info .info:last-of-type {
  margin-bottom: 15px;
}

.cookies-info .info-text {
  flex: 1;
  display: flex;
  flex-flow: row wrap;
  font-size: 12px;
}

.cookies-info .info-header {
  width: 100%;
  text-align: left;
  font-weight: bold;
  margin-bottom: 5px;
  font-size: 13px;
  color: #ff5733;
}

.cookies-info .info-description {
  width: 100%;
  text-align: left;
}

.use-of-cookies {
  background: rgba(0, 31, 63);
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: row wrap;
  overflow-y: auto;
  padding: 0.5rem 1rem;
}

.cookies-header {
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  width: 100%;
  color: #f4ebd0;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.cookies-header svg,
.cookies-info-header svg {
  position: absolute;
  top: 3px;
  right: 3px;
  cursor: pointer;
  fill: #ff5733;
}

.cookies-info-header svg {
  top: 8px;
  right: 10px;
}

.cookies-text {
  width: 100%;
  font-family: 'Raleway', sans-serif;
  font-size: 14px;
  color: #f4ebd0;
  margin-bottom: 5px;
}

.cookie-status {
  width: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cookies-link {
  color: #ff5733;
  font-weight: bold;
  cursor: pointer;
  text-decoration: underline;
}

.cookies-options {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 10px auto;
  flex-flow: row wrap;
}

.cookies-options button {
  background-color: #f4ebd0;
  border-color: #ff5733;
  color: #ff5733;
  margin: 5px 10px;
}

.cookies-options button:hover {
  background-color: #ff5733;
  ;
  color: #f4ebd0;
}

.form-check-input:checked {
  background-color: #ff5733;
  border-color: #ff5733;
}
</style>